import './Homepage.css'
import './SocialMediaIcons.css'
import React from 'react'
import { VerticalTimeline } from 'components/VerticalTimeline/VerticalTimeline'
import { Loader } from 'components/Loader/Loader'
import { timelineApiCall } from 'data/timeline_api_call'
import { Button } from 'components/Button/Button'
import { MultiTimelines } from 'components/MultiTimelines/MultiTimelines'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import { route as timelinePageRoute } from 'scenes/TimelinePage/route'
import { isDesktop } from 'utils/is_desktop'
import { wikiApiCall } from 'data/wiki_api_call'
import isURL from 'validator/lib/isURL'


export const Homepage = () => {
  const inputRef = React.useRef()
  React.useEffect(() => {
    inputRef.current.focus()
  }, [])

const navigate = useNavigate()

  const [inputText, setInputText] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [showDef, toggleDef] = React.useState(false)

  const [timelines, setTimelines] = React.useState([])

  const defRef = React.useRef()
  const onSubtitleClick = () => {
    if (!showDef) {
      defRef.current.classList.remove("invisible-def")
      defRef.current.classList.add("visible-def")
    } else {
      defRef.current.classList.remove("visible-def")
      defRef.current.classList.add("invisible-def")
    }
    toggleDef(!showDef)
  }

  const goToTimelinePage = (webpage) => {
    const params = new URLSearchParams()
    params.append("url1", webpage)
    const url = `${timelinePageRoute}?${params.toString()}`
    window.gtag('set', 'page_path', url)
    window.gtag('event', 'page_view')
    navigate(url)
  }

  const onCreateTimeline = async (event) => {
    event.preventDefault()
    if (!inputText) {
      alert("Please enter an input")
      return
    }

    if (!isURL(inputText, { require_protocol: true })) {
      const wikiUrl = await wikiApiCall(inputText)
      if (wikiUrl) {
        goToTimelinePage(wikiUrl)
      } else {
        alert("No results found, try another input")
        return
      }
    } else {
      goToTimelinePage(inputText)
    }


    // const url = `${timelinePageRoute}/${encodeURIComponent(inputText)}`
    // window.gtag('set', 'page_path', url)
    // window.gtag('event', 'page_view')
    // navigate(url)
    // setIsLoading(true)

    // const data = await timelineApiCall(linkText)
    // // const data2 = await timelineApiCall('https://en.wikipedia.org/wiki/Jeff_Bezos')
    // setTimeline(data)

    // // setTimelines([data, data2])
    // setIsLoading(false)
  }

  return (
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <div
        style={{ padding: "15px" }}
      >
        <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/timefunc/" className="fa fa-facebook"></a>
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/timefunction" className="fa fa-twitter"></a>
      </div>
      <div style={{ paddingTop: isDesktop() ? "100px" : "50px" }}>
        <h1 className="top-headings" style={{ textAlign: "center" }}>TimeFunction</h1>
        <h3 className="top-headings" style={{ textAlign: "center" }}>Automatically generate a timeline on any topic. Instantly.</h3>
        <div
          className="top-headings"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "22px",
            marginBottom: "22px",
          }}
        >
          <p
            id="subtitle"
            onClick={onSubtitleClick}
            style={{
              cursor: "pointer",
              margin: "0px",
              color: "#9a9a9a",
              fontStyle: "italic",
              fontWeight: "bold",
            }}>
              How does it work?
            </p>
            <p style={{ paddingLeft: "30px", margin: "0px", fontStyle: "italic" }}>
              <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/VMxS75QVHAXrzfHZ9">Join the TimeFunction tribe</a>
            </p>
        </div>
        {/* <h4
          id="subtitle"
          onClick={onSubtitleClick}
          className="top-headings"
          style={{
            cursor: "pointer",
            textAlign: "center",
            fontStyle: "italic",
            color: "#9a9a9a",
          }}>
            How does it work?
        </h4> */}
        <div
          ref={defRef}
          style={{
            display: showDef ? undefined : "none",
            fontSize: "small",
            width: isDesktop() ? "600px" : "75%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <p>
            After entering a person, place, or historical event, TimeFunction will scrape data from <a href="https://wikipedia.org" target="_blank" rel="noopener noreferrer">wikipedia.org</a> to construct your timeline.
          </p>
          <p>
            Alternatively, you can input the URL to any webpage you would like, and TimeFunction will try its best to convert it into a timeline. Keep in mind that TimeFunction currently only officially supports <a href="https://wikipedia.org" target="_blank" rel="noopener noreferrer">wikipedia.org</a> links. Finally, share the generated timeline with friends and colleagues by sending them the link.
          </p>
          {/* <p>
            Simply put, a function for autogenerating timelines. Given a link to a webpage (we recommend <a href="https://wikipedia.org" target="_blank" rel="noopener noreferrer">wikipedia.org</a> pages, but can be any webpage), TimeFunction will generate a timeline of events in chronological order. Share the generated timeline with friends and colleagues by sending them the link.
          </p> */}
          <p>
            Some examples of timelines you can generate:
          </p>
            <ul>
              <li><Link to="/timeline?url1=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FIsaac_Newton">Isaac Newton</Link>, generated from <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Isaac_Newton">https://en.wikipedia.org/wiki/Isaac_Newton</a></li>
              <li><Link to="/timeline?url1=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCold_War">The Cold War</Link>, generated from <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Cold_War">https://en.wikipedia.org/wiki/Cold_War</a></li>
              <li><Link to="/timeline?url1=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FMing_dynasty">The Ming Dynasty</Link>, generated from <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Ming_dynasty">https://en.wikipedia.org/wiki/Ming_dynasty</a></li>
              <li><Link to="/timeline?url1=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FAlexander_the_Great">Alexander the Great</Link>, generated from <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Alexander_the_Great">https://en.wikipedia.org/wiki/Alexander_the_Great</a></li>
            </ul>
        </div>
        <div style={{ textAlign: "center" }}>
          <form onSubmit={onCreateTimeline}>
            <input
              id="link-input"
              ref={inputRef}
              placeholder="Enter a person, place, or historical event..."
              value={inputText}
              type="text"
              onChange={(event) => setInputText(event.currentTarget.value)}
              style={{
                width: isDesktop() ? "600px" : "75%",
                height: "18px",
                padding: "16px",
                fontSize: "16px",
                border: "1px solid white",
                boxShadow: "1px 2px 3px 1px #9a9a9a",
                transition: "box-shadow 0.5s",
                borderRadius: "8px",
              }}
            />
            <br />
            <Button
              type="submit"
              id="timeline-button"
              disabled={isLoading}
            >
              Create Timeline
            </Button>
          </form>
          {isLoading ? <Loader /> : null}
        </div>
      </div>
      <footer
          style={{
            marginTop: "auto",
            padding: "12px",
          }}
        >
           <small>Copyright &copy; { new Date().getFullYear()}, TimeFunction. All Rights Reserved</small>
           <small style={{ marginLeft: "15px" }}><a href="/privacy-policy.html">Privacy Policy</a></small>
        </footer>
    </div>
  )
}
