export const wikiApiCall = async (search) => {
  const url = "https://en.wikipedia.org/w/api.php?origin=*&action=query&generator=search&gsrlimit=1&prop=info&inprop=url&format=json&gsrsearch="
  const data = await fetch(`${url}${search}`).then(response => {
    return response.json()
  })

  console.log("data:")
  console.log(data)
  if (data.query) {
    const url = Object.values(data.query.pages)[0].fullurl
    return url
  } else {
    return null
  }
}
