import React from 'react'
import './styles.css'
import { dateNumToMonth, displayDate, initializeNumNonDateSents } from './utils'

export const Section = React.forwardRef(({ year, yearData, rightSide, numTimelines, numCards }, ref) => {

  const rightSideAnimation = rightSide ? {
    transform: "perspective(300px) rotateY(90deg)",
    transformOrigin: "top left",
  } : {}

  let numNulls = 0
  const cards = yearData.map((cardData, index) => {
    if (!cardData) {
      numNulls++
      return null
    }

    const lineLength = (numNulls * 584) + 100
    numNulls = 0
    return (
      <>
        <div
        style={{
          backgroundColor: "white",
          width: `${lineLength}px`,
          height: "1px",
          boxShadow: "0px 0px 10px 1px #9a9a9a",
          background: "linear-gradient(to left, white, #ebebeb)"
        }}
      />
        <div
          className="text-container"
          style={{
            border: "1px solid white",
            padding: "16px",
            borderRadius: "8px",
            width: "450px",
            backgroundColor: "white",
            borderTopRightRadius: rightSide ? "8px" : "0px",
            borderTopLeftRadius: rightSide ? "0px" : "8px",
            boxShadow: "-2px -2px 12px -5px #9a9a9a",
            height: "fit-content",
          }}
        >
          <h2 style={{ margin: "0px" }}>{displayDate(year)} <span style={{ color: "#9a9a9a", fontSize: "12px" }}>{cardData.source}</span></h2>
          <CardContent cardData={cardData.data} />
        </div>
      </>
    )
  })

  return (
    <section
      className="time-section"
      ref={ref}
      style={{
          position: "relative",
          display: "flex",
          transform: "perspective(300px) rotateY(-90deg)",
          transformOrigin: "top right",
          transformStyle: "preserve-3d",
          transition: "transform 0.7s",
          marginBottom: "20px",
          ...rightSideAnimation
        }}>
      {rightSide &&
				<div
					style={{
						position: "absolute",
						width: "12px",
						height: "12px",
						backgroundColor: "white",
						borderRadius: "50%",
						top: "-6px",
						left: "-6px",
						boxShadow: "0px 0px 8px 1px"
					}}
				/>
			}
      {cards}
    </section>
  )
})

const CardContent = ({ cardData }) => {

  const [numNonDateSents, setNumNonDateSents] = React.useState(initializeNumNonDateSents(cardData))

  const onShowMore = (id, increment) => {
    setNumNonDateSents({
      ...numNonDateSents,
      [id]: numNonDateSents[id] + increment,
    })
  }

  const months = Object.keys(cardData).sort((a, b) => a - b)
  const divsForYear = months.map(month => {
    const paragraphsForMonth = cardData[month].map((dateObj, index) => {
      const id = dateObj.id
      const showMoreButton = dateObj.non_date_sents.length > numNonDateSents[id] ? (
      <button
        className="show-more-button"
        onClick={onShowMore.bind(null, id, 2)}
      >
        Show more
      </button> ) : null

      const extraText = dateObj.non_date_sents.filter((_, index) => index < numNonDateSents[id])

      const paraText = [dateObj.date_sentence, ...extraText]
        .join(" ")
        .split("\n")
        .filter(t => t !== "")

      const paragraphs = paraText.map((text, index) => {
        // If there's only one dataObj for the year, don't need to add padding
        // because it's not contained inside a div
        const padding = cardData[month].length === 1 ? "0px" : "12px"
        return <p style={{ padding }}>{text}{index + 1 === paraText.length && showMoreButton}</p>
      })
      
      return cardData[month].length !== 1 ? 
        <div style={{ boxShadow: "-2px -2px 12px -5px #9a9a9a" }}>
          {paragraphs}
        </div> : paragraphs
    })
    return (
      <div>
        {month !== 0 && <h4>{dateNumToMonth[month]}</h4>}
        {paragraphsForMonth}
      </div>
    )
  })
  return divsForYear
}

// export const Section = React.forwardRef(({ year, yearData, rightSide }, ref) => {

//   const [numNonDateSents, setNumNonDateSents] = React.useState(initializeNumNonDateSents(yearData))

//   const onShowMore = (id, increment) => {
//     setNumNonDateSents({
//       ...numNonDateSents,
//       [id]: numNonDateSents[id] + increment,
//     })
//   }

//   const rightSideAnimation = rightSide ? {
//     transform: "perspective(300px) rotateY(90deg)",
//     transformOrigin: "top left",
//   } : {}

//   const months = Object.keys(yearData).sort((a, b) => a - b)
//   const divsForYear = months.map(month => {
//     const paragraphsForMonth = yearData[month].map((dateObj, index) => {
//       const id = dateObj.id
//       const showMoreButton = dateObj.non_date_sents.length > numNonDateSents[id] ? (
//       <button
//         className="show-more-button"
//         onClick={onShowMore.bind(null, id, 2)}
//       >
//         Show more
//       </button> ) : null

//       const extraText = dateObj.non_date_sents.filter((_, index) => index < numNonDateSents[id])

//       const paraText = [dateObj.date_sentence, ...extraText]
//         .join(" ")
//         .split("\n")
//         .filter(t => t !== "")

//       const paragraphs = paraText.map((text, index) => {
//         // If there's only one dataObj for the year, don't need to add padding
//         // because it's not contained inside a div
//         const padding = yearData[month].length === 1 ? "0px" : "12px"
//         return <p style={{ padding }}>{text}{index + 1 === paraText.length && showMoreButton}</p>
//       })
      
//       return yearData[month].length !== 1 ? 
//         <div style={{ boxShadow: "-2px -2px 12px -5px #9a9a9a" }}>
//           {paragraphs}
//         </div> : paragraphs
//     })
//     return (
//       <div>
//         {month !== 0 && <h4>{dateNumToMonth[month]}</h4>}
//         {paragraphsForMonth}
//       </div>
//     )
//   })

//   console.log("HELLO?")
//   return (
//     <section
//       className="time-section"
//       ref={ref}
//       style={{
//           position: "relative",
//           display: "flex",
//           transform: "perspective(300px) rotateY(-90deg)",
//           transformOrigin: "top right",
//           transformStyle: "preserve-3d",
//           transition: "transform 0.7s",
//           marginBottom: "20px",
//           ...rightSideAnimation
//         }}>
//       {rightSide &&
// 				<div
//           style={{
//             backgroundColor: "white",
//             width: "100px",
//             height: "1px",
//             boxShadow: "0px 0px 10px 1px #9a9a9a",
//             background: "linear-gradient(to left, white, #ebebeb)"
// 					}}
// 				/>
// 			}
//       {rightSide &&
// 				<div
// 					style={{
// 						position: "absolute",
// 						width: "12px",
// 						height: "12px",
// 						backgroundColor: "white",
// 						borderRadius: "50%",
// 						top: "-6px",
// 						left: "-6px",
// 						boxShadow: "0px 0px 8px 1px"
// 					}}
// 				/>
// 			}
//       <div
//         className="text-container"
//         style={{
//           border: "1px solid white",
//           padding: "16px",
//           borderRadius: "8px",
//           backgroundColor: "white",
//           borderTopRightRadius: rightSide ? "8px" : "0px",
//           borderTopLeftRadius: rightSide ? "0px" : "8px",
//           boxShadow: "-2px -2px 12px -5px #9a9a9a",
//         }}
//       >
//         <h2 style={{ margin: "0px" }}>{displayDate(year)}</h2>
//           {divsForYear}
//       </div>
//     </section>
//   )
// })
