import { isChromeExtension } from "utils/is_chrome_extension"

export const timelineApiCall = async (link) => {
  if (!link) {
    return null
  }

  // const host = isChromeExtension() ? "http://localhost:5000" : ""
  const host = 'https://api-dot-timefunction-358922.uc.r.appspot.com'
  // const host = "api"

  const data = await fetch(`${host}/get_timeline?link=${link}`, {
    method: 'GET',
  }).then(response => {
    return response.json()
  })

  return data
}
