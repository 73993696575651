import React from 'react'
import './styles.css'
import { dateNumToMonth, displayDate, initializeNumNonDateSents } from './utils'

const NUM_SENTENCES_TO_SHOW = 6

export const DoubleSection  = React.forwardRef(({ year, yearData1, yearData2, source1, source2, isDesktop }, ref) => {
  const { ref1, ref2 } = ref
  return (
    <div style={{ display: "flex" }}>
      <Section
          source={source1}
          ref={ref1}
          year={year}
          yearData={yearData1}
          rightSide={false}
          isDesktop={isDesktop}
        />
      <Section
          source={source2}
          ref={ref2}
          year={year}
          yearData={yearData2}
          rightSide={true}
          isDesktop={isDesktop}
        />
    </div>
  )
})

export const Section = React.forwardRef(({ year, yearData, rightSide, isDesktop, source }, ref) => {

  const rightSideCss = rightSide && isDesktop ? {
    marginLeft: "auto",
  } : {}

  const rightSideAnimation = rightSide ? {
    transform: "perspective(300px) rotateY(90deg)",
    transformOrigin: "top left",
  } : {}

  return (
    <section
      className="time-section"
      ref={ref}
      style={{
          position: "relative",
          display: "flex",
          transform: "perspective(300px) rotateY(-90deg)",
          transformOrigin: "top right",
          transformStyle: "preserve-3d",
          transition: "transform 0.7s",
          marginBottom: "20px",
          ...rightSideCss,
          ...rightSideAnimation
        }}>
      {rightSide &&
				<div
            style={{
						backgroundColor: "white",
						width: "100%",
						height: "1px",
						// marginLeft: "auto",
						boxShadow: "0px 0px 10px 1px #9a9a9a",
						background: "linear-gradient(to left, white, #ebebeb"
					}} 
				/>
			}
      {rightSide &&
				<div
					style={{
						position: "absolute",
						width: "12px",
						height: "12px",
						backgroundColor: "white",
						borderRadius: "50%",
						top: "-6px",
						left: "-6px",
						boxShadow: "0px 0px 8px 1px"
					}}
				/>
			}
      <div
        className="text-container"
        style={{
          border: "1px solid white",
          padding: "16px",
          borderRadius: "8px",
          backgroundColor: "white",
          borderTopRightRadius: rightSide ? "8px" : "0px",
          borderTopLeftRadius: rightSide ? "0px" : "8px",
          boxShadow: "-2px -2px 12px -5px #9a9a9a",
          height: "fit-content",
          ...rightSideCss
        }}
      >
        <h2 style={{ margin: "0px" }}>
          {displayDate(year)}
          <span style={{ marginLeft: "5px", color: "#9a9a9a", fontSize: "12px" }}>{source}</span>
        </h2>
          <YearContent yearData={yearData} />
      </div>
      {!rightSide &&
				<div
					style={{
						backgroundColor: "white",
						width: "100%",
						height: "1px",
						boxShadow: "0px 0px 10px 1px #9a9a9a",
						background: "linear-gradient(to right, white, #ebebeb"
					}} 
				/>
			}
      {!rightSide &&
				<div
					style={{
						position: "absolute",
						width: "12px",
						height: "12px",
						backgroundColor: "white",
						borderRadius: "50%",
						top: "-6px",
						right: "-6px",
						boxShadow: "0px 0px 8px 1px"
					}}
				/>
			}
    </section>
  )
})

const YearContent = ({ yearData }) => {
  const [numNonDateSents, setNumNonDateSents] = React.useState(initializeNumNonDateSents(yearData))

  const onShowMore = (id, increment) => {
    setNumNonDateSents({
      ...numNonDateSents,
      [id]: numNonDateSents[id] + increment,
    })
  }

  const months = Object.keys(yearData).sort((a, b) => a - b)
  const divsForYear = months.map(month => {
    const paragraphsForMonth = yearData[month].map((dateObj, index) => {
      const id = dateObj.id
      const showMoreButton = dateObj.non_date_sents.length > numNonDateSents[id] ? (
      <button
        className="show-more-button"
        onClick={onShowMore.bind(null, id, NUM_SENTENCES_TO_SHOW)}
      >
        Show more
      </button> ) : null

      const extraText = dateObj.non_date_sents.filter((_, index) => index < numNonDateSents[id])

      const paraText = [dateObj.date_sentence, ...extraText]
        .join(" ")
        .split("\n")
        .filter(t => t !== "")

      const paragraphs = paraText.map((text, index) => {
        // If there's only one dataObj for the year, don't need to add padding
        // because it's not contained inside a div
        const padding = yearData[month].length === 1 ? "0px" : "12px"
        return <p style={{ padding }}>{text}{index + 1 === paraText.length && showMoreButton}</p>
      })

      const img = dateObj.img_src ? (
        <img src={dateObj.img_src} style={{ display: "block", marginLeft: "auto", marginRight: "auto", paddingBottom: "12px"}}/>
      ) : null

      return yearData[month].length !== 1 ? 
        <div style={{ boxShadow: "-2px -2px 12px -5px #9a9a9a" }}>
          {paragraphs}
          {img}
        </div> : (
          <>
            {paragraphs}
            {img}
          </>
        )
    })
    return (
      <div>
        {month !== 0 && <h4>{dateNumToMonth[month]}</h4>}
        {paragraphsForMonth}
      </div>
    )
  })
  return divsForYear
}
