import { Loader } from 'components/Loader/Loader'
import { VerticalTimeline } from 'components/VerticalTimeline/VerticalTimeline'
import { timelineApiCall } from 'data/timeline_api_call'
import React from 'react'
import { Link, useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

export const TimelinePage = () => {

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [timeline, setTimeline] = React.useState()
  const [timeline2, setTimeline2] = React.useState()

  
  const getTimeline = async (url) => {
    const data = await timelineApiCall(searchParams.get(url))
    if (data.error) {
      alert("No results found")
      navigate('/')
    } else {
      if (url === 'url1') {
        setTimeline(data)
      } else {
        setTimeline2(data)
      }
    }
  }

  const getTimelines = async () => {
    await Promise.all([
      getTimeline('url1'),
      getTimeline('url2')
    ])
  }

  React.useEffect(() => {
    if (searchParams && searchParams.get('url1') && !searchParams.get('url2')) {
      getTimeline('url1')
    } else if (searchParams && searchParams.get('url1') && searchParams.get('url2')) {
      getTimelines()
    }
  }, [searchParams])

  if (searchParams.get('url1') && !searchParams.get('url2')) {

    return timeline ? (
      <div>
        <h4
          id="home-button"
          style={{ marginLeft: "24px" }}
          onClick={() => {
            setTimeline(undefined)
            window.gtag('set', 'page_path', '/')
            window.gtag('event', 'page_view')
            navigate('/')
          }}
        >
          TimeFunction
        </h4>
        {Object.values(timeline.timeline_data).length > 0 ? (
          <VerticalTimeline timeline={timeline} />
          ) : (
            <h4 style={{ textAlign: "center" }}>No Results were found. Go back to <Link to="/">TimeFunction</Link></h4>
          )
        }
        
      </div>
    ) : <Loader />
  } else if (searchParams.get('url1') && searchParams.get('url2')) {
    return timeline && timeline2 ? (
      <div>
        <h4
          id="home-button"
          style={{ marginLeft: "24px" }}
          onClick={() => {
            setTimeline(undefined)
            window.gtag('set', 'page_path', '/')
            window.gtag('event', 'page_view')
            navigate('/')
          }}
        >
          TimeFunction
        </h4>
        {Object.values(timeline.timeline_data).length > 0 ? (
          <VerticalTimeline timeline={timeline} timeline2={timeline2} />
          ) : (
            <h4 style={{ textAlign: "center" }}>No Results were found. Go back to <Link to="/">TimeFunction</Link></h4>
          )
        }
        
      </div>
    ) : <Loader />
  } else {
    return "No data source provided in the URL."
  }

}
