import React from 'react'
import Select from 'react-select'

export const Selector = ({ label, containerStyling, ...props }) => {
  return (
    <div
      style={{
        marginBottom: "24px",
        ...containerStyling
      }}
    >
      <label
        style={{
          display: "block",
          paddingBottom: "4px",
          fontWeight: "600",
        }}
      >
       {label}
      </label>
      <Select {...props} />
    </div>
  )
}
