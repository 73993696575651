import { Button } from 'components/Button/Button'
import { Selector } from 'components/Selector/Selector'
import React from 'react'
import { DoubleSection, Section } from './Section'
import { useSearchParams } from "react-router-dom"

export const DualLines = ({
  timeline1,
  timeline2,
  isDesktop,
  itemsRef,
  setTimeline1,
  setTimeline2,
}) => {
  const allTimelineData = [timeline1, timeline2]

  const allTimelineDatesWithRepeats = 
    Object.keys(timeline1.timelineData)
      .concat(Object.keys(timeline2.timelineData)
      .sort((a, b) => a - b)
    )

  if (itemsRef.current.length !== allTimelineDatesWithRepeats.length) {
    itemsRef.current = Array(allTimelineDatesWithRepeats.length)
      .fill()
      .map((_, i) => itemsRef.current[i] || React.createRef())
  }

  const allTimelineDates = [...new Set(allTimelineDatesWithRepeats)]

  const superTimeline = allTimelineDates.reduce((prev, year) => {
    return {
      ...prev,
        [year]: allTimelineData.map(tl => {
        if (tl.timelineData[year]) {
          return {
            source: tl.header,
            data: tl.timelineData[year]
          }
        } else {
          return null
        }
      }).filter(item => item)
    }
  }, {})

  const allDates = Object.keys(timeline1.timelineData)
    .concat(Object.keys(timeline2.timelineData))
    .sort((a, b) => a - b)
  // `console.log('all Dates allallll: ', allDates)
  // console.log('supertimeline: ', superTimeline)
  const used = {}
  const sections = allDates.map((year, index) => {
    if (allDates.length > index + 1 && year === allDates[index + 1]) {
      used[index + 1] = true
      return (
        <DoubleSection
          key={index}
          year={year}
          yearData1={superTimeline[year][0].data}
          yearData2={superTimeline[year][1].data}
          source1={superTimeline[year][0].source}
          source2={superTimeline[year][1].source}
          ref={{
            ref1: itemsRef.current[index],
            ref2: itemsRef.current[index + 1]
          }}
          isDesktop={isDesktop}
        />
      )
    } else if (!used[index]) {
      const yearData = superTimeline[year][0].data
      const source = superTimeline[year][0].source
      const rightSide = isDesktop ? source === timeline2.header : true
      return (
        <Section
          source={source}
          ref={itemsRef.current[index]}
          key={index}
          year={year}
          yearData={yearData}
          rightSide={rightSide}
          isDesktop={isDesktop}
        />
      )
    } else {
      return null
    }
  }).filter(item => item)

  return (
    <>
      <HeadingArea
        timeline1={{ ...timeline1, setTimeline: setTimeline1 }}
        timeline2={{ ...timeline2, setTimeline: setTimeline2 }}
      />
      <div
        id="line-of-time"
        style={{
          position: "absolute",
          width: "1px",
          backgroundColor: "white", 
          height: "100%",
          boxShadow: "0px 0px 10px 1px #9a9a9a",
        }}
      />
      {sections}
    </>
  )
}

const HeadingArea = ({ timeline1, timeline2 }) => {

  const [searchParams, setSearchParams] = useSearchParams()

  const onRemoveTimeline1 = () => {
    timeline1.setTimeline({
      header: timeline2.header,
      cover_img_src: timeline2.cover_img_src,
      timeline_data: timeline2.allTimelineData,
      entities: timeline2.entities,
    })
    timeline2.setTimeline({})
    searchParams.set("url1", searchParams.get("url2"))
    searchParams.delete("url2")
    setSearchParams(searchParams)
  }

  const onRemoveTimeline2 = () => {
    timeline2.setTimeline({})
    searchParams.delete("url2")
    setSearchParams(searchParams)
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          <h1>{timeline1.header}</h1>
          <img
            src={timeline1.cover_img_src}
            style={{
              display: "block",
              objectFit: "contain",
              paddingBottom: "12px",
            }}
          />
        </div>
        <div>
          <h1>{timeline2.header}</h1>
          <img
            src={timeline2.cover_img_src}
            style={{
              display: "block",
              objectFit: "contain",
              paddingBottom: "12px",
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Filters tl={timeline1} />
        <Filters tl={timeline2} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={onRemoveTimeline1}>Remove Timeline</Button>
        <Button onClick={onRemoveTimeline2}>Remove Timeline</Button>
      </div>
    </>
  )
}

const Filters = ({ tl }) => {
  return (
    <div>
      <Selector
        label="People"
        placeholder="Filter on people..."
        isMulti
        options={tl.peopleOptions}
        onChange={(newPeople) => {
          tl.setPeople(newPeople)
        }}
      />
      <Selector
        label="Locations"
        placeholder="Filter on locations..."
        isMulti
        options={tl.locationOptions}
        onChange={(newLocs) => {
          tl.setLocs(newLocs)
        }}
      />
      <Selector
        label="Organizations"
        placeholder="Filter on organizations..."
        isMulti
        options={tl.orgOptions}
        onChange={(newOrgs) => {
          tl.setOrgs(newOrgs)
        }}
        containerStyling={{ marginBottom: "36px" }}
      />
    </div>
  )
}
