export const genericLoadingPhrases = [
  "Building time machine...",
  "Crunching historical events...",
  "Reading historical documents...",
  "Consulting history experts...",
  "Drawing a line of time...",
  "Sequencing historical events..."
]

export const funnyLoadingPhrases = [
  '"All things come to those who wait" -Lady Mary Montgomerie Currie',
  "It's not you, it's me.",
  "... at least you're not on hold...",
  "We're traveling through time as fast as we can!",
  "Go ahead -- hold your breath!",
  "Mining some Bitcoin...",
  "Loading funny messages...",
  "Taking extra time to ensure we don't create any time paradoxes.",
  "Time travel is no joke, it literally takes time...",
  "There's no time like the present!"
]

export const randomItemFromArray = arr =>
  arr[Math.floor(Math.random() * arr.length)]

export const randomGenericPhrase = () =>
  randomItemFromArray(genericLoadingPhrases)


export const randomFunnyPhrase = () =>
  randomItemFromArray(funnyLoadingPhrases)
