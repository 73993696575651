/*global chrome*/

import React from 'react'
import { VerticalTimeline } from 'components/VerticalTimeline/VerticalTimeline'
import { timelineApiCall } from 'data/timeline_api_call'
import { Loader } from 'components/Loader/Loader'

export const Extension = () => {

  const [timeline, setTimeline] = React.useState()
  const [url, setUrl] = React.useState()

  const getTimeline = async (url) => {
    const data = await timelineApiCall(url)
    setTimeline(data)
  }

  React.useEffect(() => {
    console.log('mounting')

    // chrome.storage.sync.get(['isButtonClick'], (data) => {
    //   if (data && data.isButtonClick === 'yes') {
    //     chrome.storage.sync.set({ 'isButtonClick': 'no' })
    //     return
    //   }
    // })
    chrome.storage.sync.get(['url'], (data) => {
      if (data.url) {
        setUrl(data.url)
        const cachedTimeline = localStorage.getItem(data.url)
        if (cachedTimeline) {
          setTimeline(JSON.parse(cachedTimeline))
        } else {
          getTimeline(data.url)
        }
      }
    })
  }, [])

  React.useEffect(() => {
    if (timeline && url) {
      localStorage.setItem(url, JSON.stringify(timeline))
    }
  }, [timeline])
  
  // Make width like 50% of viewport width?
  return (
    // <div id="popup-container" style={{ width: "800px" }}>
    <div id="popup-container" style={{ width: "100%" }}>
      {timeline ? (
        <VerticalTimeline timeline={timeline} />
      ) : <Loader />
      }
    </div>
  )
}
