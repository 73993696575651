import React from 'react'
import "./styles.css"

export const Button = ({ style, ...props }) => (
  <button
    style={{
      margin: "24px",
      textDecoration: "none",
      border: "1px solid white",
      boxShadow: "1px 2px 3px 1px #9a9a9a",
      backgroundColor: "white",
      transition: "box-shadow 0.5s",
      borderRadius: "4px",
      padding: "8px",
      fontSize: "16px",
      ...style,
    }}
    {...props}
    className="tf-button"
  >
    {props.children}
  </button>
)
