import React from 'react'
import { isDesktop as getIsDesktop} from 'utils/is_desktop'
import { Selector } from '../Selector/Selector'
import { Section } from './Section'
import './styles.css'
import { useTimeline } from './useTimeline'
import { isScrolledIntoView } from './utils'
import isURL from 'validator/lib/isURL'
import { wikiApiCall } from 'data/wiki_api_call'
import { timelineApiCall } from 'data/timeline_api_call'
import { DualLines } from './DualLines'
import { Loader } from 'components/Loader/Loader'
import { useSearchParams } from "react-router-dom"

export const VerticalTimeline = ({ timeline = {}, timeline2 = {} }) => {

  const [_timeline1, setTimeline1] = React.useState(timeline)
  const [_timeline2, setTimeline2] = React.useState(timeline2)

  const t1 = useTimeline(_timeline1)
  const t2 = useTimeline(_timeline2)

  const [searchParams, setSearchParams] = useSearchParams()

  const prevWindowWidth = React.useRef(window.innerWidth)
  const [isDesktop, setIsDesktop] = React.useState(getIsDesktop())
  const [inputText, setInputText] = React.useState('')

  const [t2Loader, setT2Loader] = React.useState(false)

  const itemsRef = React.useRef([])

  const makeItemsViewable = () => {
    itemsRef.current.forEach(el => {
      if (isScrolledIntoView(el.current)) {
        el.current.classList.add('inView')
      } else {
        // el.current.classList.remove('inView')
      }
    })
  }

  const onResize = () => {
    if ((prevWindowWidth.current < 768 && window.innerWidth >= 768) ||
      (prevWindowWidth.current >= 768 && window.innerWidth < 768)) {
      setIsDesktop(getIsDesktop())
    }
    prevWindowWidth.current = window.innerWidth
  }

  const getTimeline = async (url) => {
    setT2Loader(true)
    const data = await timelineApiCall(url)
    setT2Loader(false)
    if (data.error) {
      alert("No results found")
    } else {
      if (Object.values(data.timeline_data).length === 0) {
        alert("No results found")
      } else {
        setTimeline2(data)
        searchParams.set("url2", url)
        setSearchParams(searchParams)
      }
    }
  }

  const onCreateSecondTimeline = async (event) => {
    event.preventDefault()
    if (!inputText) {
      alert("Please enter an input")
      return
    }

    if (!isURL(inputText, { require_protocol: true })) {
      const wikiUrl = await wikiApiCall(inputText)
      if (wikiUrl) {
        getTimeline(wikiUrl)
      } else {
        alert("No results found, try another input")
        return
      }
    } else {
      getTimeline(inputText)
    }
    setInputText('')
  }

  React.useEffect(() => {    
    window.addEventListener("scroll", makeItemsViewable)
    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("scroll", makeItemsViewable)
      window.removeEventListener("resize", onResize)
    }
  }, [])

  React.useEffect(() => {
    makeItemsViewable()
  }, [t1.timelineData, t2.timelineData])

  return (
    <div
      id="timeline-container"
      style={{
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
    >
      <div
        id="timeline"
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        {t2.timelineData ? (
          <DualLines
            timeline1={t1}
            timeline2={t2}
            isDesktop={isDesktop}
            itemsRef={itemsRef}
            setTimeline1={setTimeline1}
            setTimeline2={setTimeline2}
          />
        ) : (
          <SingleLine
            isDesktop={isDesktop}
            timeline={t1}
            itemsRef={itemsRef}
            onCreateSecondTimeline={onCreateSecondTimeline}
            inputText={inputText}
            setInputText={setInputText}
            t2Loader={t2Loader}
          />
        )}
      </div>
    </div>
  )
}

const SingleLine = ({
  isDesktop,
  timeline,
  itemsRef,
  setInputText,
  inputText,
  onCreateSecondTimeline,
  t2Loader,
}) => {
  const {
    timelineData,
    header,
    cover_img_src,
    setPeople,
    setLocs,
    setOrgs,
    peopleOptions,
    locationOptions,
    orgOptions,
  } = timeline

  const timelineDates = Object.keys(timelineData).sort((a, b) => a - b)
  if (itemsRef.current.length !== timelineDates.length) {
    itemsRef.current = Array(timelineDates.length)
      .fill()
      .map((_, i) => itemsRef.current[i] || React.createRef())
  }

  const sections = timelineDates.map((key, index) => {
    const rightSide = isDesktop ? index % 2 === 1 : true
    return <Section ref={itemsRef.current[index]} key={key} year={key} yearData={timelineData[key]} rightSide={rightSide} isDesktop={isDesktop} />
  })

  return (
    <>
      <h1 id="heading">{header}</h1>
      <div
        style={{
          display: isDesktop ? "flex" : "block",
          width: "100%",
          paddingBottom: "50px",
        }}
      >
        <img
          src={cover_img_src}
          style={{
            display: "block",
            objectFit: "contain",
            paddingBottom: "12px",
          }}
        />
        <div style={{ width: "100%", paddingLeft: cover_img_src && isDesktop ? "50px" : undefined }}>
          <Selector
            label="People"
            placeholder="Filter on people..."
            isMulti
            options={peopleOptions}
            onChange={(newPeople) => {
              setPeople(newPeople)
            }}
          />
          <Selector
            label="Locations"
            placeholder="Filter on locations..."
            isMulti
            options={locationOptions}
            onChange={(newLocs) => {
              setLocs(newLocs)
            }}
          />
          <Selector
            label="Organizations"
            placeholder="Filter on organizations..."
            isMulti
            options={orgOptions}
            onChange={(newOrgs) => {
              setOrgs(newOrgs)
            }}
            containerStyling={{ marginBottom: "36px" }}
          />
          <form onSubmit={onCreateSecondTimeline}>
            {
              t2Loader ? (
                <Loader
                  showPhrases={false}
                  style={{
                    marginTop: "0px",
                    height: "60px",
                  }}
                />
              ) : (
                <input
                  id="second-timeline-input"
                  placeholder="Generate a second timeline to compare..."
                  value={inputText}
                  type="text"
                  onChange={(event) => setInputText(event.currentTarget.value)}
                  style={{
                    width: "100%",
                    border: "1px solid #CCCCCC",
                    minHeight: "38px",
                    borderLeft: "0px",
                    borderRight: "0px",
                    borderTop: "0px",
                    fontSize: "24px",
                    paddingLeft: "14px",
                  }}
                />
              )
            }
          </form>
        </div>
      </div>
      <div
        id="line-of-time"
        style={{
          position: "absolute",
          width: "1px",
          backgroundColor: "white", 
          height: "100%",
          boxShadow: "0px 0px 10px 1px #9a9a9a",
        }}
      />
      {sections}
    </>
  )
}
