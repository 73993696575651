import React from 'react'
import { Extension } from './scenes/Extension/Extension'
import { Homepage } from './scenes/Homepage/Homepage'
import { isChromeExtension } from './utils/is_chrome_extension'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { route as TimelinePageRoute } from 'scenes/TimelinePage/route'
import { TimelinePage } from 'scenes/TimelinePage/TimelinePage'

const App = () => {
  if (isChromeExtension()) {
    return <Extension />
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage /> } />
          <Route path={`${TimelinePageRoute}`} element={<TimelinePage />} />
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App
