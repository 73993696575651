import React from 'react'
import './styles.css'
import { randomFunnyPhrase, randomGenericPhrase } from './utils'

export const Loader = ({ style, showPhrases = true }) => {

  const [counter, setCounter] = React.useState(0)
  const [phrase, setPhrase] = React.useState(randomGenericPhrase())

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter(prevCount => prevCount + 1)
    }, 4000)

    return () => clearInterval(intervalId)
  }, [])

  React.useEffect(() => {
    if (counter < 4) {
      setPhrase(randomGenericPhrase())
    } else {
      setPhrase(randomFunnyPhrase())
    }
  }, [counter])

  return (
    <div style={{ marginTop: "100px", ...style }}>
      {showPhrases && <p style={{ textAlign: "center" }}>{phrase}</p>}
      <div className="loader" />
    </div>
  )
}
