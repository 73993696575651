import React from 'react'
import { createOptions } from './utils'

export const useTimeline = (timeline) => {

  const {
    header,
    cover_img_src,
    timeline_data: allTimelineData,
    entities
  } = timeline

  const [timelineData, setTimelineData] = React.useState(allTimelineData)

  // It's possible this causes a bug where after applying a filter, all the dates just show again.
  // Might be because there's some random extra re-renders?

  // Fixed by updating if header changes. Kinda hacky, since mystery re-renders still happen,
  // But it doesn't affect the timeline at least.
  React.useEffect(() => {
    setTimelineData(allTimelineData)
  }, [header])

  const peopleOptions = createOptions(entities ? entities.PEOPLE : [])
  const locationOptions = createOptions(entities ? entities.LOCATIONS : [])
  const orgOptions = createOptions(entities ? entities.ORGANIZATIONS : [])

  const [selectedPeople, setPeople] = React.useState([])
  const [selectedLocs, setLocs] = React.useState([])
  const [selectedOrgs, setOrgs] = React.useState([])

  React.useEffect(() => {
    if (selectedPeople.length === 0 && selectedLocs.length === 0 && selectedOrgs.length === 0) {
      setTimelineData(allTimelineData)
    } else {
      const newTimelineData = Object.keys(allTimelineData).reduce((prev, year) => {
        const yearData = allTimelineData[year]
        const months = Object.keys(yearData).sort((a, b) => a - b)
        const newYearData = months
          .map(month => {
            const dateObjs = yearData[month].filter(dateObj => {
              const allEntities = [...selectedPeople, ...selectedLocs, ...selectedOrgs]
              return dateObj.entities.PEOPLE.some(ent => allEntities.map(s => s.value).includes(ent)) ||
                dateObj.entities.LOCATIONS.some(ent => allEntities.map(s => s.value).includes(ent)) ||
                dateObj.entities.ORGANIZATIONS.some(ent => allEntities.map(s => s.value).includes(ent))
            })
            return dateObjs
          })
          .filter(dateObjs => dateObjs.length > 0)
          .reduce((prev, dateObjArray) => {
            return {
              ...prev,
              [dateObjArray[0].month]: dateObjArray
            }
          },  {})

        if (Object.keys(newYearData).length > 0) {
          return {
            ...prev,
            [year]: newYearData
          }
        } else {
          return prev
        }
      }, {})
      setTimelineData(newTimelineData)
    }
  }, [selectedPeople, selectedLocs, selectedOrgs])

  return {
    entities,
    allTimelineData,
    timelineData,
    header,
    cover_img_src,
    setPeople,
    setLocs,
    setOrgs,
    peopleOptions,
    locationOptions,
    orgOptions
  }
}
