export const TABLET_WIDTH = 768

export const createOptions = (arr) => arr
  .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())
  )
  .map(item => ({
    value: item,
    label: item,
  }))

export const isScrolledIntoView = (el) => {
  const rect = el.getBoundingClientRect()
  const isVisible = rect.top <= window.innerHeight
  
  return isVisible
}

export const initializeNumNonDateSents = (yearData) =>
  Object.keys(yearData).reduce((prev, month) => {
    const ids = yearData[month]
      .map(dateObj => {
        return {
          [dateObj.id]: 0
        }
      })
      .reduce((prev, idObj) => {
        const id = Object.keys(idObj)[0]
        return {
          ...prev,
          [id]: 0
        }
      }, {})

    return {
      ...prev,
      ...ids
    }
  }, {})

export const displayDate = (date) => {
  if (date < 0) {
    return `${Math.abs(date)} BCE`
  } else {
    return date
  }
}
  
export const dateNumToMonth = {
  0: '',
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}
